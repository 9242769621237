import React, { useState, useEffect } from "react";
import { Route, BrowserRouter, Switch } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Hidden } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Home from "./components/HomePage/Home";
import TopTab from "./components/TopNav";
// import Footer from './components/Footer/Footer'
import ExpWalkin from "./components/Walkins/ExpWalkin";
import FresherWalkin from "./components/Walkins/FreshersWalkin";
import ExpOpening from "./components/Openings/ExpOpening";
import FreshersOpening from "./components/Openings/FreshersOpening";
import About from "./components/AboutUs/About";
import Openings from "./components/Openings/Openings";
import Document from "./components/Documents/Document";
import Testimonial from "./components/Testimonial/Testimonial";
import axios from "axios";
import Footer from "./components/Footer/Footer";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      marginTop: "50px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "60px",
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: "60px",
    },
  },
}));

function App() {
  const [value, setValue] = React.useState(0);
  const [testimonial_data, setTestimonial_data] = useState([]);
  var date1 = new Date();
  var date = new Date();
  useEffect(() => {
    axios
      .get(
        "https://raw.githubusercontent.com/SelflessFamily-Files/SelflessWebsiteFiles/master/Testimonials/testimonial.json"
      )
      .then((response) => setTestimonial_data(response.data))
      .catch((e) => console.error(e));
  }, []);

  const classes = useStyles();
  return (
    <>
      {/* {console.log(
        new Date().toString() >=
          "Sat Jan 01 2022 00:00:00 GMT+0530 (India Standard Time)"
      )} */}
      {/* {new Date().toString() >=
      "Sat Jan 01 2022 00:00:00 GMT+0530 (India Standard Time)" ? ( */}
      <div className={classes.root}>
        <CssBaseline />
        <div>
          <TopTab value={value} setValue={setValue} />
        </div>
        <Switch>
          <div style={{ minHeight: "100vh" }}>
            <Route exact path="/">
              <Home
                setValue={setValue}
                testimonial_data={testimonial_data.slice(0, 8)}
              />
            </Route>
            <Route exact path="/openings">
              <Openings setValue={setValue} />
            </Route>
            <Route exact path="/aboutus">
              <About setValue={setValue} />
            </Route>
            <Route exact path="/documents">
              <Document setValue={setValue} />
            </Route>
            <Route exact path="/fresherswalkin">
              <FresherWalkin setValue={setValue} />
            </Route>
            <Route exact path="/experiencewalkin">
              <ExpWalkin setValue={setValue} />
            </Route>
            <Route exact path="/testimonial">
              <Testimonial testimonial_data={testimonial_data} />
            </Route>
            <Route exact path="/experienceopenings">
              <ExpOpening setValue={setValue} />
            </Route>
            <Route exact path="/freshersopenings">
              <FreshersOpening setValue={setValue} />
            </Route>
          </div>
        </Switch>
        <Footer />
      </div>
      {/* ) : (
        <div style={{ textAlign: "center" }}>
          <h1>I will be ariving tomorrow, Stay tuned</h1>
          <h1>Happy New Year</h1>
        </div>
      )} */}
    </>
  );
}

export default App;
